import React from 'react';
import BackgroundImg from './images/video-img.jpg';
import Video from './images/video-background.mp4';
import Confetti from 'react-confetti';

const HalvingComplete = () => {
  return (
    <div
      className="countdown-ctn"
      style={{ backgroundImage: 'url(' + BackgroundImg + ')' }}
    >
      <Confetti width={window.innerWidth} height={450} />
      <video
        playsInline
        autoPlay
        muted
        loop
        poster={BackgroundImg}
        id="ecash_background"
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div className="overlay" />
      <div className="gradient-transition2" />
      <div className="gradient-transition" />
      <div className="block">
        <div className="container">
          <div className="countdown-inner-ctn">
            <h4>Reward Halving Complete!</h4>
            <p>
              The eCash block reward has decreased from 6,250,000 to 3,125,000
              XEC on block 840,000
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HalvingComplete;
