import React, { useState, useEffect } from 'react';
import 'animate.css';
import SiteLinks from './SiteLinks/SiteLinks.js';
import Blip from './images/blip.png';
import Logo from './images/logo-mark-white.png';
import World from './images/globe.png';
import CountUp from 'react-countup';
import { useSpring, animated } from 'react-spring';
import HalvingComplete from './HalvingComplete.js';

function formatNumber(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const App = () => {
  const [xec, setXec] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [inflation, setInflation] = useState([0, 0, 0]);
  const totalSupply = 21000000000000;
  const next_halving_supply_amount = 19687500000000;
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [increasePerTenSec, setincreasePerTenSec] = useState(5208);
  const [loading, setLoading] = useState(true);
  const props = useSpring({
    val: seconds,
    from: { val: 0 },
    config: { duration: 1000 },
  });
  const [error, setError] = useState(false);

  const updateSupply = () => {
    const priceApi = 'https://ecash.supply/api/supply/XEC';
    fetch(priceApi)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          let supply = data.circulatingSupply.toFixed();
          let trillionformat = (supply / 1000000000000).toFixed(4);
          let width = ((supply / totalSupply) * 100).toFixed();
          let perPerson = formatNumber((supply / 8000000000).toFixed());
          setXec([supply, trillionformat, width, perPerson]);
          setSeconds(parseInt(supply));
          if (supply > next_halving_supply_amount) {
            setincreasePerTenSec(5208);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(true);
        setLoading(false);
      });
  };

  const updateInflation = () => {
    const blocks_per_year = 52560;
    const block_reward = 3125000;
    const current_supply = parseInt(xec[0]);
    let _24h_supply_increase = 144 * block_reward;
    let yesterday_supply = current_supply - _24h_supply_increase;
    let _24h_inflation_rate =
      ((current_supply - yesterday_supply) / yesterday_supply) * 100;

    let year_end_supply = blocks_per_year * block_reward + current_supply;
    let yearly_inflation_rate =
      ((year_end_supply - current_supply) / current_supply) * 100;

    setInflation([_24h_inflation_rate, yearly_inflation_rate]);
  };

  useEffect(() => {
    updateSupply();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + increasePerTenSec);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    updateInflation();
  }, [xec]);

  return (
    <div className="wrapper">
      <HalvingComplete />
      <div className="header-ctn">
        <div className="header animate__animated animate__flipInY">
          <h1 className="glitch" data-text="eCash Supply">
            eCash Supply
          </h1>
          <img src={Blip} alt="ecash" className="blip" />
        </div>
      </div>
      {error && (
        <div className="error">Error loading data. Please try again later.</div>
      )}
      {!error && (
        <>
          <div className="bar-ctn animate__animated animate__fadeInUp animate-delay">
            <h3>Supply Breakdown</h3>
            <div className="bar">
              <div className="flip-indicator fi-1"></div>
              <div className="flip-indicator fi-2"></div>
              <div className="flip-indicator fi-3"></div>
              <div className="flip-indicator fi-4"></div>
              <div className="fi-5"></div>
              <div
                className="inner-bar"
                style={{
                  width: xec[2] + '%',
                }}
              >
                <div className="percentage-label">
                  <CountUp
                    start={0}
                    end={xec[1]}
                    duration={3}
                    decimals={2}
                    decimal="."
                    suffix=" Trillion"
                  ></CountUp>
                </div>
                <img src={Logo} alt="ecash" className="ecash-icon" />
              </div>
            </div>
          </div>

          {show2 && (
            <div>
              <div
                className="modal-backdrop animate__animated animate__fadeIn"
                style={{
                  width: '100vw',
                  height: '100vh',
                }}
                onClick={() => setShow2(false)}
              >
                <div
                  className="modal-border animate__animated animate__flipInX"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-inner">
                    The eCash supply is designed to be inflationary until the
                    final supply cap is met. New supply comes in the form of
                    block rewards awarded to miners for securing the network.
                    The reward is currently 3,125,000 XEC per block. One block
                    is mined roughly every 10 minutes. This reward is cut in
                    half every 210,000 blocks.
                    <br />
                    <br />
                    This fundamental design feature provides the incentive
                    necessary for miners to dedicate resources to the network,
                    but is also designed to slow the supply increase as time
                    goes on and adoption increases.
                    <br />
                    <br />
                    The supply counter on this page is increasing in real time
                    at a rate of {formatNumber(increasePerTenSec / 10)} XEC per
                    second. This rate is based on the average block being mined
                    every ten minutes.
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="number-block-ctn animate__animated animate__fadeInUp animate-delay-2">
            <h3
              className="question"
              // onMouseEnter={() => setShow2(true)}
              // onMouseLeave={() => setShow2(false)}
              onClick={() => setShow2(!show2)}
            >
              Current Supply <span>?</span>
            </h3>

            <div className="number-block animate__animated animate__fadeInUp animate-delay-3 count-supply">
              <animated.div className="numberCount">
                {props.val.interpolate((val) => formatNumber(val.toFixed()))}
              </animated.div>
            </div>

            <h3>Total Supply</h3>
            <div
              className="number-block animate__animated animate__fadeInUp"
              style={{ animationDelay: '2s' }}
            >
              <CountUp
                start={0}
                end={totalSupply}
                duration={5}
                decimals={0}
                decimal="."
                separator=","
                suffix=" XEC"
                useEasing={true}
              ></CountUp>
              {/* {formatNumber(totalSupply)} XEC */}
            </div>
            <h3>24h Inflation Rate</h3>
            <div
              className="number-block animate__animated animate__fadeInUp"
              style={{ animationDelay: '2.5s' }}
            >
              <CountUp
                start={0}
                end={inflation[0]}
                duration={5}
                decimals={4}
                decimal="."
                separator=","
                suffix="%"
                useEasing={true}
              ></CountUp>
            </div>
            <h3>Yearly Inflation Rate</h3>
            <div
              className="number-block animate__animated animate__fadeInUp"
              style={{ animationDelay: '2.7s' }}
            >
              <CountUp
                start={0}
                end={inflation[1]}
                duration={5}
                decimals={2}
                decimal="."
                separator=","
                suffix="%"
                useEasing={true}
              ></CountUp>
            </div>
            <h3
              className="question"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              Yearly Inflation Rate
              <br />
              After Next Halving<span>?</span>
              {show && (
                <div className="tool-tip">
                  Around January 2028, the eCash block reward will decrease by
                  half. This planned halving, along with the always approaching
                  supply cap, means a deflationary trend is happening.
                </div>
              )}
            </h3>
            <div
              className="number-block animate__animated animate__fadeInUp"
              style={{ animationDelay: '3s' }}
            >
              <CountUp
                start={0}
                end={0.4}
                duration={5}
                decimals={2}
                decimal="."
                separator=","
                suffix="%"
                useEasing={true}
              ></CountUp>
            </div>
          </div>

          <div
            className="per-person-ctn animate__animated animate__fadeInUp"
            style={{ animationDelay: '3s' }}
          >
            <p>
              Currently there are roughly{' '}
              <span style={{ fontWeight: '700', color: '#00abe7' }}>
                {xec[3]}
              </span>{' '}
              XEC for each of the{' '}
              <span style={{ fontWeight: '700' }}>8.0B</span> people on Earth{' '}
              <img src={World} alt="world" />
            </p>

            <p>Get yours today!</p>

            <a
              href="https://buyecash.com/"
              className="glow-on-hover"
              target="_blank"
              rel="noreferrer"
            >
              Get eCash
            </a>
          </div>
        </>
      )}
      <SiteLinks />
    </div>
  );
};

export default App;
